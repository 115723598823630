import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import JSONData from "../assets/content/conditionsofuse.json"

const conditionsofuse = () => {
  return (
    <Layout>
      <Seo
        title="Condition of Use"
        description="Condition of use for azszero app"
      />

      <div style={{ maxWidth: `960px`, margin: `1.45rem` }}>
        <h1>{JSONData.title}</h1>
        {JSONData.content.map((data, index) => {
          return (
            <div
              key={`content_item_${index}`}
              dangerouslySetInnerHTML={{ __html: data.item }}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export default conditionsofuse
